<template>
  <v-layout>
    <v-flex :xs12="!sidebar" :xs8="sidebar">
      <overview-header
        v-if="showHeader"
        :title="title"
        :icon="icon"
        :store="store"
        :sidebar="isSidebarTogglable"
        :searchable="searchable"
        :sidebar-icon="sidebarIcon"
        @toggle-sidebar="sidebar = !sidebar"
      >
        <template slot="toolbar-additional-search">
          <slot name="toolbar-additional-search" />
        </template>
        <template slot="toolbar-actions">
          <slot name="toolbar-actions" />
        </template>
      </overview-header>
      <v-card>
        <slot name="content">
          <v-data-table
            v-model="selected"
            :headers="internalHeaders"
            :items="ids"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :rows-per-page-items="[20, 50, 100]"
            disable-page-reset
            :loading="isLoading || isError"
            class="elevation-1"
            :class="{
              'table-overview--has-actions': !!actions.length && stickyThead,
              'table-overview--sticky-thead': stickyThead,
              'table-overview--sticky-first-column': stickyFirstColumn,
              'table-overview--striped-columns': stripedColumns
            }"
            disable-initial-sort
            must-sort
            :select-all="selectAll"
          >
            <template slot="headerCell" slot-scope="props">
              <v-tooltip v-if="hasInfoText(props.header.info)" bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    {{ props.header.text }}
                  </span>
                </template>
                <span>
                  {{ props.header.info }}
                </span>
              </v-tooltip>
              <span v-else>
                {{ props.header.text }}
              </span>
            </template>
            <v-progress-linear
              slot="progress"
              :color="isError ? 'error' : 'accent'"
              indeterminate
              :height="3"
            />
            <template slot="items" slot-scope="props">
              <slot v-bind="props" name="table-row" />
            </template>
          </v-data-table>
        </slot>
      </v-card>
    </v-flex>
    <v-flex v-if="sidebar" xs4>
      <slot name="sidebar">
        <overview-filter
          v-if="hasFiltersSlot"
          v-bind:style="[
            sidebarSticky ? { position: 'sticky', top: '88px' } : {}
          ]"
          :store="store"
        >
          <template slot="filters">
            <slot name="filters" />
          </template>
        </overview-filter>
      </slot>
    </v-flex>
    <overview-actions :actions="actions" />
  </v-layout>
</template>
<script>
import {
  applyFilters,
  applyPagination,
  applySort,
  getHash,
  getValuesFromURLSearchParams
} from "@/api/url";
import { isChanged } from "@/store/helpers";
import { forEach, head, isNil } from "lodash";
import { createNamespacedHelpers } from "vuex";
import OverviewActions from "./OverviewActions";
import OverviewHeader from "./OverviewHeader";
import OverviewFilter from "./OverviewFilter";
import {
  FILTERS as GETTER_FILTERS,
  IDS as GETTER_IDS,
  IS_ERROR as GETTER_IS_ERROR,
  IS_LOADING as GETTER_IS_LOADING,
  PAGINATION as GETTER_PAGINATION
} from "../../../store/templates/table/getter-types";
import {
  SET_PAGINATION as DISPATCH_SET_PAGINATION,
  FETCH as DISPATCH_FETCH
} from "../../../store/templates/table/action-types";
import { SET_FILTER } from "@/store/templates/table/mutation-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");

export default {
  name: "table-overview",
  components: {
    OverviewFilter,
    OverviewHeader,
    OverviewActions
  },
  props: {
    title: {
      type: String,
      required: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: undefined
    },
    store: {
      type: String,
      required: true
    },
    headers: {
      type: Array,
      default: () => []
    },
    actions: {
      type: Array,
      default: () => []
    },
    searchable: {
      type: Boolean,
      default: false
    },
    sidebarIcon: {
      type: String,
      default: "far fa-fw fa-filter"
    },
    forceInitialSort: {
      type: [Boolean, String],
      default: false
    },
    descending: {
      type: Boolean,
      default: false
    },
    sidebarOpenByDefault: {
      type: Boolean,
      default: false
    },
    sidebarSticky: {
      type: Boolean,
      default: false
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    stickyThead: {
      type: Boolean,
      default: true
    },
    stickyFirstColumn: {
      type: Boolean,
      default: false
    },
    stripedColumns: {
      type: Boolean,
      default: false
    },
    stateInHash: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sidebar: false,
      selected: []
    };
  },
  watch: {
    filters: {
      handler(newVal) {
        Object.keys(newVal).forEach(key => newVal => {
          const val = newVal[key];
          this.$store.commit(`${this.store}/${SET_FILTER}`, { prop: key, val });
        });
        this.setHash();
      },
      deep: true
    },
    selected(val) {
      this.$emit("set-selected", val);
    }
  },
  created() {
    this.sidebar = this.sidebarOpenByDefault;
    this.applyHashAndInitialSort();
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    hasSidebarSlot() {
      return !isNil(this.$slots.sidebar);
    },
    hasFiltersSlot() {
      return !isNil(this.$slots.filters);
    },
    isSidebarTogglable() {
      return this.hasSidebarSlot || this.hasFiltersSlot;
    },
    isLoading() {
      return this.$store.getters[`${this.store}/${GETTER_IS_LOADING}`];
    },
    isError() {
      return this.$store.getters[`${this.store}/${GETTER_IS_ERROR}`];
    },
    ids() {
      return this.$store.getters[`${this.store}/${GETTER_IDS}`];
    },
    filters() {
      return this.$store.getters[`${this.store}/${GETTER_FILTERS}`];
    },
    pagination: {
      get() {
        return this.$store.getters[`${this.store}/${GETTER_PAGINATION}`];
      },
      set(pagination) {
        // if (this.ids <= 0 && !isChanged(this.pagination, pagination)) {
        //   this.$store.dispatch(`${this.store}/${DISPATCH_SET_PAGINATION}`, {
        //     pagination
        //   });
        //   this.setHash();
        // }
        if (isChanged(this.pagination, pagination)) {
          // Only set when pagination has actually changed, to prevent the fetch call being called
          // multiple times.
          this.$store.dispatch(`${this.store}/${DISPATCH_SET_PAGINATION}`, {
            pagination
          });
          this.setHash();
        }
      }
    },
    internalHeaders() {
      return this.headers.filter(header =>
        this.hasRolesOrPermissions(header.rolesOrPermissions)
      );
    }
  },
  methods: {
    applyHashAndInitialSort() {
      if (!this.stateInHash) {
        this.$store.dispatch(`${this.store}/${DISPATCH_FETCH}`);
        return;
      }
      const hash = getHash();
      const hashParams = getValuesFromURLSearchParams(
        new URLSearchParams(hash)
      );

      this.applyHashToFilters(hashParams);
      this.applyHashAndInitialSortToPagination(hashParams);
    },
    applyHashToFilters(hashParams) {
      if (!isNil(hashParams.filter)) {
        forEach(Object.keys(hashParams.filter), prop => {
          let value = hashParams.filter[prop];
          this.$store.commit(`${this.store}/${SET_FILTER}`, { prop, value });
        });
      }
    },
    applyHashAndInitialSortToPagination(hashParams) {
      const pagination = Object.assign({}, this.pagination);

      if (!isNil(hashParams.sort)) {
        // If the hash contains a sort, this takes priority
        const isDescending = hashParams.sort.indexOf("-") === 0;
        pagination.sortBy = isDescending
          ? hashParams.sort.substr(1)
          : hashParams.sort;
        pagination.descending = isDescending;
      } else if (
        this.forceInitialSort &&
        !(typeof this.forceInitialSort === "string")
      ) {
        // If it doesn't, but forceInitialSort is on, try and sort on the first sortable header
        const sortableHeader = head(
          this.headers.filter(header => header.sortable !== false)
        );
        if (!isNil(sortableHeader)) {
          pagination.sortBy = sortableHeader.value;
          pagination.descending =
            !isNil(sortableHeader.type) &&
            ["id", "timestamp"].includes(sortableHeader.type);
        }
      } else if (typeof this.forceInitialSort === "string") {
        // If it doesn't, but forceInitialSort is on, try and sort on the first sortable header
        pagination.sortBy = this.forceInitialSort;
        pagination.descending = this.descending;
      }

      if (!isNil(hashParams.page)) {
        pagination.page = hashParams.page;
      }

      if (!isNil(hashParams.perPage)) {
        pagination.perPage = hashParams.perPage;
      }

      this.pagination = pagination;
    },
    setHash() {
      if (!this.stateInHash) {
        return;
      }
      const searchParams = new URLSearchParams();
      applyFilters(searchParams, this.filters);
      applyPagination(searchParams, this.pagination);
      applySort(searchParams, this.pagination);
      window.location.hash = searchParams.toString();
    },
    hasInfoText(data) {
      return !isNil(data);
    }
  }
};
</script>
<style lang="scss">
.table-overview {
  &--has-actions {
    .v-table__overflow {
      --table-overview__max-height-offset: 280px;
    }
  }

  &--sticky-thead {
    --table-overview__max-height-offset: 240px;

    .v-table__overflow {
      max-height: calc(100vh - var(--table-overview__max-height-offset));
      overflow-y: auto;
    }

    table {
      width: 100%;
    }

    thead tr {
      border-bottom: none !important;
    }

    th[role="columnheader"] {
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 10;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
    }
  }

  &--sticky-first-column {
    th:first-child {
      background-color: white;
      z-index: 11 !important;
    }

    td:first-child {
      background-color: inherit;
      z-index: 10;
    }

    th:first-child,
    td:first-child {
      position: sticky;
      left: 0;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        border-right: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    tbody,
    tr {
      background-color: inherit;
    }
  }

  &--striped-columns {
    th:nth-child(even),
    td:nth-child(even) {
      background-color: #e8e8e8;
    }
  }
}
</style>
