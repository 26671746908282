var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('v-flex',{attrs:{"xs12":!_vm.sidebar,"xs8":_vm.sidebar}},[(_vm.showHeader)?_c('overview-header',{attrs:{"title":_vm.title,"icon":_vm.icon,"store":_vm.store,"sidebar":_vm.isSidebarTogglable,"searchable":_vm.searchable,"sidebar-icon":_vm.sidebarIcon},on:{"toggle-sidebar":function($event){_vm.sidebar = !_vm.sidebar}}},[_c('template',{slot:"toolbar-additional-search"},[_vm._t("toolbar-additional-search")],2),_c('template',{slot:"toolbar-actions"},[_vm._t("toolbar-actions")],2)],2):_vm._e(),_c('v-card',[_vm._t("content",[_c('v-data-table',{staticClass:"elevation-1",class:{
            'table-overview--has-actions': !!_vm.actions.length && _vm.stickyThead,
            'table-overview--sticky-thead': _vm.stickyThead,
            'table-overview--sticky-first-column': _vm.stickyFirstColumn,
            'table-overview--striped-columns': _vm.stripedColumns
          },attrs:{"headers":_vm.internalHeaders,"items":_vm.ids,"pagination":_vm.pagination,"total-items":_vm.pagination.totalItems,"rows-per-page-items":[20, 50, 100],"disable-page-reset":"","loading":_vm.isLoading || _vm.isError,"disable-initial-sort":"","must-sort":"","select-all":_vm.selectAll},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"headerCell",fn:function(props){return [(_vm.hasInfoText(props.header.info))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(props.header.text)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(props.header.info)+" ")])]):_c('span',[_vm._v(" "+_vm._s(props.header.text)+" ")])]}},{key:"items",fn:function(props){return [_vm._t("table-row",null,null,props)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-progress-linear',{attrs:{"slot":"progress","color":_vm.isError ? 'error' : 'accent',"indeterminate":"","height":3},slot:"progress"})],1)])],2)],1),(_vm.sidebar)?_c('v-flex',{attrs:{"xs4":""}},[_vm._t("sidebar",[(_vm.hasFiltersSlot)?_c('overview-filter',{style:([
          _vm.sidebarSticky ? { position: 'sticky', top: '88px' } : {}
        ]),attrs:{"store":_vm.store}},[_c('template',{slot:"filters"},[_vm._t("filters")],2)],2):_vm._e()])],2):_vm._e(),_c('overview-actions',{attrs:{"actions":_vm.actions}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }