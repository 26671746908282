<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Filter</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <slot name="filters" />
    </v-card-text>
    <v-card-actions>
      <v-btn flat color="accent" @click.native="fetch">
        Search
      </v-btn>
      <v-btn
        flat
        color="accent"
        :disabled="!hasActiveFilters"
        @click.native="clear"
      >
        Clear
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { HAS_ACTIVE_FILTERS as GETTER_HAS_ACTIVE_FILTERS } from "../../../store/templates/table/getter-types";
import {
  FETCH as DISPATCH_FETCH,
  CLEAR_FILTER as DISPATCH_CLEAR_FILTER
} from "../../../store/templates/table/action-types";

export default {
  name: "overview-filter",
  props: {
    store: {
      type: String,
      required: true
    }
  },
  computed: {
    hasActiveFilters() {
      return this.$store.getters[`${this.store}/${GETTER_HAS_ACTIVE_FILTERS}`];
    }
  },
  methods: {
    fetch() {
      this.$store.dispatch(`${this.store}/${DISPATCH_FETCH}`);
    },
    clear() {
      this.$store.dispatch(`${this.store}/${DISPATCH_CLEAR_FILTER}`);
    }
  }
};
</script>
